// tested with LandingRebrand.test.tsx
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export type LandingHeadingProps = {
	variant: string;
};

const LandingHeading = styled(Typography)<{ component?: React.ElementType }>(() => ({
	fontFamily: "var(--heading-font)",
	color: "var(--heading-color)",
	fontWeight: 400,
	lineHeight: 1,
}));

export default LandingHeading;
