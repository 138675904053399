export class ArrayIndexError extends Error {}

/**
 * Get a pseudo-random element from within an array.
 *
 * @param arrayOfEntries - T[]
 * @returns an entry within arrayOfEntries
 */
export default function getRandomArrayEntry<T>(arrayOfEntries: T[]): T {
	if (arrayOfEntries.length === 0) {
		throw new ArrayIndexError("getRandomArrayEntry requires an array with a length greater than zero");
	}
	return arrayOfEntries[Math.floor(Math.random() * arrayOfEntries.length)];
}
