import useAuth from "@hooks/useAuth";
import LandingRouter from "@lib/feature/landing/LandingRouter";
import MainView from "@lib/feature/MainView";
import serverSideTranslationProps from "@lib/util/serverSideTranslationProps";
import type { GetStaticProps } from "next";

export const getStaticProps: GetStaticProps = async (context) => {
	return serverSideTranslationProps(context);
};

export default function HomePage() {
	const { isUser } = useAuth();
	if (isUser) {
		return <MainView />;
	}

	return <LandingRouter />;
}
