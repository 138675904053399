import DialogButton from "@components/DialogButton";
import GenericBanner from "@components/GenericBanner";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Trans, useTranslation } from "next-i18next";
import { NotificationMessageProps } from "../types";

export default function UpdateIOSMessage({ onClose: handleClose }: NotificationMessageProps) {
	const { t } = useTranslation("common");
	return (
		<GenericBanner
			title={t("outdated_ios_banner.banner_title")}
			subtitle={t("outdated_ios_banner.banner_subtitle")}
			button={
				<DialogButton
					size="small"
					sx={{ height: "fit-content" }}
					onClose={handleClose}
					renderDialog={(open, onClose) => {
						return (
							<Dialog open={open} onClose={onClose} PaperProps={{ elevation: 0 }}>
								<DialogTitle>{t("outdated_ios_dialog.dialog_title")}</DialogTitle>
								<DialogContent sx={{ p: 0 }}>
									<Box component="ol">
										<Typography component="li">{t("outdated_ios_dialog.update_ios_step_1")}</Typography>
										<Typography component="li">{t("outdated_ios_dialog.update_ios_step_2")}</Typography>
										<Typography component="li">{t("outdated_ios_dialog.update_ios_step_3")}</Typography>
										<Typography component="li">
											<Trans
												t={t}
												i18nKey={"outdated_ios_dialog.update_ios_step_4"}
												components={[<strong key="0" />]}
											/>
										</Typography>
									</Box>
								</DialogContent>
								<DialogActions>
									<Button onClick={onClose}>{t("close_button")}</Button>
								</DialogActions>
							</Dialog>
						);
					}}
					dialogRoutingName="outdated-ios"
				>
					{t("outdated_ios_banner.banner_button_label")}
				</DialogButton>
			}
		/>
	);
}
