// tested with LandingRebrand.test.tsx
import { styled } from "@mui/material";
import { useTranslation } from "next-i18next";
import LandingHeading, { LandingHeadingProps } from "./LandingHeading";

const StyledLandingHeading = styled(LandingHeading)(({ theme }) => ({
	fontSize: theme.spacing(10),
	textAlign: "center",
}));

export default function LandscapeHeadings({ variant }: LandingHeadingProps) {
	const { t } = useTranslation("common");
	return (
		<StyledLandingHeading
			component="h1"
			sx={{
				zIndex: 1,
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				mx: "60px",
				position: "absolute",
				top: "50%",
				left: 0,
				right: 0,
				transform: "translate(0, -50%)",
			}}
		>
			<span>{t(`landing_copy_variants.${variant}_start`)}</span>
			<span>{t(`landing_copy_variants.${variant}_end`)}</span>
		</StyledLandingHeading>
	);
}
