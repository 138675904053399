import { accountRegisterPath } from "@api/account";
import { surveySeen } from "@api/survey";
import { ElementType } from "react";
import { useSWRConfig } from "swr";
import useFeatureFlags from "../featureFlags/useFeatureFlags";
import { BKDRAug2024HiringLink, NPSSurveyAvailableMessage } from "./message/SurveyAvailableMessage";
import { NotificationMessageProps } from "./types";

type CurrentNotification = null | {
	Message: ElementType<NotificationMessageProps>;
	onClose: () => void;
};

export default function useServerNotification(): CurrentNotification {
	const flags = useFeatureFlags();
	const { mutate } = useSWRConfig();
	const surveyName = flags?.show_survey;

	if (surveyName != null) {
		return {
			Message: surveyName === "bkdr_hiring_aug_2024" ? BKDRAug2024HiringLink : NPSSurveyAvailableMessage,
			onClose: async () => {
				await surveySeen({ survey: { name: surveyName } });
				await mutate(accountRegisterPath);
			},
		};
	}

	return null;
}
