import useWindowResize from "@hooks/useWindowResize";
import { RefObject, useEffect, useState } from "react";
import textfit from "textfit";

const MAX_FONT_SIZE = 60;
const MIN_FONT_SIZE = 16;

type UseFitTextToParent<T extends HTMLElement = HTMLElement> = (ref: RefObject<T>, textContent: string) => boolean;

const useFitTextToParent: UseFitTextToParent = (ref, textContent) => {
	const windowSize = useWindowResize();
	const [isResized, setIsResized] = useState(false);

	useEffect(() => {
		if (ref.current == null) {
			return;
		}
		textfit(ref.current, { minFontSize: MIN_FONT_SIZE, maxFontSize: MAX_FONT_SIZE, multiLine: true, widthOnly: true });
		setIsResized(true);
	}, [ref, windowSize, textContent]);

	return isResized;
};

export default useFitTextToParent;
