import useLocale from "@hooks/useLocale";
import getSupportLinks from "@lib/util/getSupportLinks";
import { Trans, useTranslation } from "next-i18next";
import TranslationExternalLink from "./TranslationExternalLink";

export default function NeedHelpFAQ() {
	const { t } = useTranslation("common");
	const locale = useLocale();
	const externalLink = getSupportLinks("faq", locale);
	return (
		<Trans
			t={t}
			i18nKey="location_request.location_disabled_help"
			components={[<TranslationExternalLink key="faq" href={externalLink} />]}
		/>
	);
}
