import { Viewport } from "@hooks/useViewportSize";
type ImageSetProps = {
	viewport: Viewport;
	variant: string;
	pathToAssets?: string;
};
type ImageSourceProps = ImageSetProps & {
	resolution: number;
};

const RESOLUTION_SIZE_COUNT = 3;

// Also tested in:
// lib/feature/landing/useLandingBackgroundImageSet.test.tsx
// lib/feature/landing/hooks/useLandingRebrandSrcSet.test.tsx
export function getImageSrc({ viewport, variant, resolution, pathToAssets = "/imgs" }: ImageSourceProps) {
	return `${pathToAssets}/${variant}_landing_background_${viewport}@${resolution}x.webp`;
}

// Also tested in:
// lib/feature/landing/hooks/useLandingRebrandSrcSet.test.tsx
export function getImageSrcSetValue({ viewport, variant, pathToAssets }: ImageSetProps) {
	return Array.from({ length: RESOLUTION_SIZE_COUNT })
		.map((_, index) => {
			const resolution = index + 1;
			return `${getImageSrc({ viewport, variant, resolution, pathToAssets })} ${resolution}x`;
		})
		.join(", ");
}

// Also tested in:
// lib/feature/landing/useLandingBackgroundImageSet.test.tsx
export function getImageSetValue({ viewport, variant, pathToAssets }: ImageSetProps) {
	return Array.from({ length: RESOLUTION_SIZE_COUNT })
		.map((_, index) => {
			const resolution = index + 1;
			return `url(${getImageSrc({ viewport, variant, resolution, pathToAssets })}) ${resolution}x`;
		})
		.join(", ");
}
