import useIsDesktop from "@hooks/useIsDesktop";
import Link from "@lib/components/Link";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Trans, useTranslation } from "next-i18next";

const CustomLink = styled(Link)(({ theme }) => ({
	color: theme.palette.common.white,
	textDecorationColor: theme.palette.common.white,
	fontSize: "11px",
}));

const perryStreetLink = [
	// eslint-disable-next-line react/jsx-key
	<CustomLink href="https://www.perrystreet.com" />,
];
const externalLinks = [
	// it's not that kind of array
	// eslint-disable-next-line react/jsx-key
	<CustomLink href="https://www.perrystreet.com/privacy" />,
	// eslint-disable-next-line react/jsx-key
	<CustomLink href="https://www.perrystreet.com/tos" />,
];

export default function Footer() {
	const { t } = useTranslation("common");
	const isDesktop = useIsDesktop();
	return (
		<Box
			sx={(theme) => ({
				width: "100%",
				display: "flex",
				flexDirection: "column",
				alignItems: isDesktop ? "flex-end" : "center",
				position: "absolute",
				bottom: isDesktop ? theme.spacing(5) : theme.spacing(3),
				right: isDesktop ? theme.spacing(14.5) : 0,
			})}
		>
			<Typography sx={{ fontSize: "11px" }}>
				<Trans
					t={t}
					i18nKey="landing.copyright"
					components={perryStreetLink}
					tOptions={{ year: new Date().getFullYear() }}
				/>
			</Typography>
			<Box>
				<Trans t={t} i18nKey="landing.links" components={externalLinks} />
			</Box>
		</Box>
	);
}
