import { post } from "@lib/httpClient";
import { Surveys } from "@lib/models";

interface SurveySeenRequest {
	survey: { name: Surveys };
}

export async function surveySeen(body: SurveySeenRequest): Promise<undefined> {
	return post<SurveySeenRequest, undefined>("/user/survey_seen", body);
}
