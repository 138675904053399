import { createWebPushSubscription } from "@api/users";
import checkForNotification from "./checkForNotification";
import checkForServiceWorker from "./checkForServiceWorker";
import { track } from "./trackAnalytics";

export default async function registerForPushNotifications() {
	if (!checkForServiceWorker() || !checkForNotification()) {
		track("notification_registration_failed", {
			serviceWorker: checkForServiceWorker(),
			notification: checkForNotification(),
		});
		return;
	}

	const serviceWorker = await navigator.serviceWorker.ready;
	const permission = await Notification.requestPermission();
	if (permission === "denied") {
		return;
	}

	track("notifications_enabled");

	const subscription = await serviceWorker.pushManager.subscribe({
		userVisibleOnly: true,
		applicationServerKey: process.env.NEXT_PUBLIC_VAPID_PUBLIC_KEY,
	});
	const keys = subscription.toJSON().keys;
	if (keys) {
		createWebPushSubscription({
			endpoint: subscription.endpoint,
			p256dh: keys.p256dh,
			auth: keys.auth,
		});
	}
}
