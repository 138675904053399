import DialogButton from "@components/DialogButton";
import GenericBanner from "@components/GenericBanner";
import isIOS from "@lib/util/isIOS";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { NotificationMessageProps } from "../types";

export default function UnsupportedBrowserMessage({ onClose: handleClose }: NotificationMessageProps) {
	const { t } = useTranslation("common");
	return (
		<GenericBanner
			title={t("unsupported_browser_banner.banner_title")}
			subtitle={t("unsupported_browser_banner.banner_subtitle")}
			button={
				<DialogButton
					size="small"
					sx={{ height: "fit-content" }}
					onClose={handleClose}
					renderDialog={(open, onClose) => {
						return (
							<Dialog open={open} onClose={onClose} PaperProps={{ elevation: 0 }}>
								<DialogTitle>{t("unsupported_browser_dialog.dialog_title")}</DialogTitle>
								<DialogContent sx={{ pb: 0 }}>
									<Typography>
										{t("unsupported_browser_dialog.dialog_body", {
											option: isIOS() ? "ios" : "other",
										})}
									</Typography>
								</DialogContent>
								<DialogActions>
									<Button onClick={onClose}>{t("close_button")}</Button>
								</DialogActions>
							</Dialog>
						);
					}}
					dialogRoutingName="unsupported-browser"
				>
					{t("unsupported_browser_banner.banner_button_label")}
				</DialogButton>
			}
		/>
	);
}
