import Link from "@components/Link";
import UnauthenticatedLanguageMenu from "@components/UnauthenticatedLanguageMenu";
import useViewportSize from "@hooks/useViewportSize";
import { track } from "@lib/util/trackAnalytics";
import { Box, Button, ButtonProps } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useTranslation } from "next-i18next";
import NextLink from "next/link";
import { useEffect } from "react";
import Footer from "./Footer";
import useLandingBackgroundImageSet from "./useLandingBackgroundImageSet";

const SHADOW_STYLE = "0px 0px 6px black";

const StyledButton = styled(Button, {
	shouldForwardProp: (prop) => prop !== "secondary",
})<ButtonProps & { secondary?: boolean }>(({ theme, secondary = false }) => ({
	borderRadius: "50px",
	color: "white",
	backgroundColor: secondary ? theme.palette.secondary.main : theme.palette.common.black,
	"&.MuiButton-root:hover": {
		backgroundColor: secondary ? theme.palette.secondary.light : theme.palette.grey[700],
	},
}));

export default function Landing() {
	const { t } = useTranslation("common");
	const viewport = useViewportSize();
	const backgroundImageSet = useLandingBackgroundImageSet();

	const isLandscape = viewport === "desktop" || viewport === "tablet_landscape";

	useEffect(() => {
		track("landing_page_visited", { source: "default" });
	}, []);

	return (
		<Box
			component="main"
			sx={{
				width: "100%",
				height: "100%",
				display: "flex",
				flexDirection: "column",
				position: "relative",
				justifyContent: isLandscape ? "center" : "flex-end",
				backgroundImage: backgroundImageSet,
				backgroundColor: "black",
				backgroundSize: "cover",
				backgroundPosition: "center",
				pt: "var(--safe-top)",
			}}
		>
			<Box
				component="h1"
				sx={(theme) => ({
					height: theme.spacing(6),
					position: "absolute",
					left: isLandscape ? theme.spacing(15) : theme.spacing(2.5),
					top: "var(--safe-top)",
					mt: theme.spacing(3),
					img: {
						filter: `drop-shadow(${SHADOW_STYLE})`,
					},
				})}
			>
				{/* eslint-disable-next-line @next/next/no-img-element */}
				<img src="/imgs/bkdr-logo-630.png" alt={t("brand_name")} style={{ width: "136px", height: "50px" }} />
			</Box>
			<UnauthenticatedLanguageMenu />
			<Box
				sx={(theme) => ({
					width: "100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "flex-start",
					justifyContent: "flex-end",
					px: isLandscape ? theme.spacing(15) : theme.spacing(2.5),
					mb: isLandscape ? 0 : theme.spacing(12),
				})}
			>
				<Typography
					sx={(theme) => ({
						fontWeight: 800,
						fontSize: isLandscape ? theme.spacing(10) : theme.spacing(4),
						lineHeight: isLandscape ? theme.spacing(10) : theme.spacing(4),
						mb: theme.spacing(2.5),
						textShadow: SHADOW_STYLE,
					})}
				>
					{t("landing.text")}
				</Typography>
				<Typography
					variant={isLandscape ? "h3" : "h5"}
					sx={(theme) => ({
						fontSize: isLandscape ? theme.spacing(6) : theme.spacing(3),
						mb: isLandscape ? theme.spacing(5) : theme.spacing(2),
						textShadow: SHADOW_STYLE,
					})}
				>
					{t("landing.subtitle")}
				</Typography>
				<Box
					sx={{
						width: "100%",
						maxWidth: isLandscape ? "385px" : "unset",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						mt: isLandscape ? "20px" : 0,
					}}
				>
					<StyledButton
						secondary
						sx={(theme) => ({ mb: theme.spacing(2.5) })}
						fullWidth
						href="/anon-sign-up"
						LinkComponent={NextLink}
						onClick={() => {
							track("cruise_anonymously_clicked", { source: "default" });
						}}
					>
						{t("landing.cruise_anonymously")}
					</StyledButton>
					<StyledButton
						sx={(theme) => ({ mb: theme.spacing(2.5) })}
						fullWidth
						href="/login"
						LinkComponent={NextLink}
						onClick={() => {
							track("log_in_clicked", { source: "default" });
						}}
					>
						{t("log_in_button")}
					</StyledButton>
					<Link
						href="/sign-up"
						variant="body2"
						sx={(theme) => ({
							color: theme.palette.common.white,
							backgroundColor: "rgba(0, 0, 0, 0.75)",
							textDecorationColor: "rgba(255, 255, 255, 0.4)",
							textAlign: "center",
							mx: "auto",
						})}
						onClick={() => {
							track("create_account_clicked", { source: "default" });
						}}
					>
						{t("create_account_button")}
					</Link>
				</Box>
			</Box>
			<Footer />
		</Box>
	);
}
