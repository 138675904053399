import checkForMobile from "@lib/util/checkForMobile";
import checkForNotification from "@lib/util/checkForNotification";
import checkForPushManager from "@lib/util/checkForPushManager";
import checkForServiceWorker from "@lib/util/checkForServiceWorker";
import isIOS from "@lib/util/isIOS";
import isMobileSafari from "@lib/util/isMobileSafari";
import isPWA from "@lib/util/isPWA";
import { track } from "@lib/util/trackAnalytics";
import getNotificationStorageKey from "./getNotificationStorageKey";
import { addNotification, hasNotificationType } from "./storage";
import { MessageType } from "./types";

function notDismissed(messageType: MessageType) {
	return window.localStorage.getItem(getNotificationStorageKey(messageType)) == null;
}

function removeExpiryByMessageType(messageType: MessageType) {
	window.localStorage.removeItem(getNotificationStorageKey(messageType));
}

function dismissedExpired(messageType: MessageType) {
	const notifcationStorageKey = getNotificationStorageKey(messageType);
	const promptPWADelay = window.localStorage.getItem(notifcationStorageKey);
	if (promptPWADelay == null) {
		return false;
	}
	const parsedDelay = parseInt(promptPWADelay);
	if (Number.isNaN(parsedDelay)) {
		removeExpiryByMessageType(messageType);
		return true;
	}
	return Date.now() > parsedDelay;
}

function handleShowBanner(messageType: MessageType) {
	if ((notDismissed(messageType) && !hasNotificationType(messageType)) || dismissedExpired(messageType)) {
		removeExpiryByMessageType(messageType);
		addNotification({ messageType: messageType });
		track(`${messageType}_banner_shown`);
	}
}

export default function checkShouldAddNotification() {
	if ((!checkForServiceWorker() && checkForMobile()) || (isIOS() && !isMobileSafari())) {
		handleShowBanner("unsupported_browser");
	} else if (!isPWA() && checkForMobile()) {
		handleShowBanner("install_pwa");
	} else if (isPWA() && isIOS() && !checkForPushManager()) {
		handleShowBanner("update_ios");
	} else if (checkForNotification() && (isPWA() || !checkForMobile()) && Notification.permission !== "granted") {
		if (
			(notDismissed("allow_notifications") && !hasNotificationType("allow_notifications")) ||
			dismissedExpired("allow_notifications")
		) {
			addNotification({ messageType: "allow_notifications" });
			track("allow_notifications_banner_shown");
		}
	}
}
