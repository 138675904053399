import DialogButton from "@components/DialogButton";
import GenericBanner from "@components/GenericBanner";
import checkForNotification from "@lib/util/checkForNotification";
import registerForPushNotifications from "@lib/util/registerForPushNotifications";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useTranslation } from "next-i18next";
import { NotificationMessageProps } from "../types";

export default function AllowNotificationsMessage({ onClose: handleClose }: NotificationMessageProps) {
	const { t } = useTranslation("common");

	const denied = checkForNotification() && Notification.permission === "denied";

	return (
		<GenericBanner
			title={t("enable_notifications_banner.banner_title")}
			subtitle={t("enable_notifications_banner.banner_subtitle")}
			button={
				denied ? (
					<DialogButton
						sx={{ height: "fit-content" }}
						onClose={handleClose}
						renderDialog={(open, onClose) => {
							return (
								<Dialog open={open} onClose={onClose} PaperProps={{ elevation: 0 }}>
									<DialogTitle>{t("notifications_disabled_dialog.dialog_title")}</DialogTitle>
									<DialogContent>{t("notifications_disabled_dialog.reenable_instructions")}</DialogContent>
									<DialogActions>
										<Button onClick={onClose}>{t("close_button")}</Button>
									</DialogActions>
								</Dialog>
							);
						}}
						dialogRoutingName="install-pwa"
					>
						{t("enable_button")}
					</DialogButton>
				) : (
					<Button
						onClick={async () => {
							await registerForPushNotifications();
							handleClose();
						}}
						sx={{ height: "fit-content" }}
					>
						{t("enable_button")}
					</Button>
				)
			}
		/>
	);
}
