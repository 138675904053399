import AppLayout from "@components/AppLayout";
import useIsAnon from "@hooks/useIsAnon";
import AnonMapToast from "@lib/feature/anon/AnonMapToast";
import useMapStatus from "@lib/feature/map/useMapStatus";
import NotificationViewer from "@lib/feature/notification/NotificationViewer";
import { Box } from "@mui/material";
import PreciseLocation from "./PreciseLocation";

export default function MainView() {
	const mapRendered = useMapStatus();
	const isAnon = useIsAnon();

	return (
		<Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
			<AppLayout>
				<Box
					component="main"
					sx={{
						flex: "1 1 auto",
						display: "flex",
						flexDirection: "column",
						alignItems: "stretch",
						justifyContent: "stretch",
					}}
				>
					{(() => {
						if (isAnon) {
							return <AnonMapToast />;
						}
						if (mapRendered && isAnon === false) {
							return <NotificationViewer />;
						}
						return null;
					})()}
					<PreciseLocation />
				</Box>
			</AppLayout>
		</Box>
	);
}
