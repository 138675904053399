import NotificationsIcon from "@mui/icons-material/Notifications";
import { Alert, AlertTitle, Typography } from "@mui/material";
import { ReactNode } from "react";
type GenericBannerProps = {
	title: string;
	subtitle: ReactNode;
	button: ReactNode;
};
export default function GenericBanner({ title, subtitle, button }: GenericBannerProps) {
	return (
		<Alert
			elevation={0}
			sx={{ color: "common.white", backgroundColor: "common.black" }}
			icon={<NotificationsIcon sx={{ color: "secondary.main" }} />}
			action={button}
		>
			<AlertTitle>{title}</AlertTitle>
			<Typography sx={{ fontSize: "14px" }}>{subtitle}</Typography>
		</Alert>
	);
}
