import Landing from "./Landing";
import LandingRebrand from "./LandingRebrand";
import useBrazilReleaseGate from "./hooks/useBrazilReleaseGate";

export default function LandingRouter() {
	const useBrazilRelease = useBrazilReleaseGate();

	if (useBrazilRelease) {
		return <LandingRebrand />;
	}
	return <Landing />;
}
