import useViewportSize from "@hooks/useViewportSize";
import { getImageSrc, getImageSrcSetValue } from "../imageSourceHelper";

export default function useLandingRebrandSrcSet(variant: string) {
	const viewportSize = useViewportSize();
	const pathToAssets = "/imgs/rebrand";
	return {
		src: getImageSrc({ viewport: viewportSize, variant, resolution: 1, pathToAssets }),
		srcSet: getImageSrcSetValue({ viewport: viewportSize, variant, pathToAssets }),
	};
}
