import { Snackbar, SnackbarContent } from "@mui/material";
import useCurrentNotification from "./useCurrentNotification";

export default function NotificationViewer() {
	const currentNotification = useCurrentNotification();

	if (currentNotification == null) {
		return null;
	}

	return (
		<Snackbar
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
			open={true}
			sx={{
				"&.MuiSnackbar-root": (theme) => ({
					zIndex: "modal",
					top: `calc(${theme.spacing(10)} + env(safe-area-inset-top, 0))`,
					[theme.breakpoints.down("sm")]: {
						left: "20px",
						right: "20px",
					},
				}),
				backgroundColor: "common.black",
			}}
		>
			<SnackbarContent
				role="presentation"
				message={<currentNotification.Message onClose={currentNotification.onClose} />}
				sx={{
					"& .MuiSnackbarContent-message": {
						p: 0,
						width: "100%",
					},
					maxWidth: "100%",
					p: 0,
					backgroundColor: "common.black",
				}}
			/>
		</Snackbar>
	);
}
