import getRandomArrayEntry from "@lib/util/getRandomArrayEntry";
import { useMemo } from "react";
import { DynamicConfig, useConfig } from "statsig-react";

const REBRAND_DEFAULT_MEDIA = "001_bike";
const REBRAND_DEFAULT_COPY = "get_on_the_map";

type RebrandVariants = "copy" | "media";

const configMap: Record<RebrandVariants, [string, string]> = {
	copy: ["rebrand_landing_copy", "copy_codes"],
	media: ["rebrand_landing_media", "background_media_codes"],
};

function useRebrandVariant(part: RebrandVariants, defaultValue: string): string {
	const [configName, configKey] = configMap[part];
	const config: DynamicConfig = useConfig(configName).config;

	return useMemo(() => {
		const variants = config.get(configKey, [defaultValue]);
		return getRandomArrayEntry(variants);
		// Config is always a new object and can't be memoized
		// we only care about the first value
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configName, configKey]);
}

export function useLandingRebrandMediaVariant() {
	return useRebrandVariant("media", REBRAND_DEFAULT_MEDIA);
}

export function useLandingRebrandCopyVariant(): string {
	return useRebrandVariant("copy", REBRAND_DEFAULT_COPY);
}
