import { Alert, Button, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import RegisterAnonModal from "./RegisterAnonModal";

export default function AnonMapToast() {
	const { t } = useTranslation("common");

	return (
		<Alert
			elevation={0}
			sx={(theme) => ({
				color: "common.white",
				backgroundColor: "common.black",
				zIndex: "modal",
				position: "fixed",
				left: "20px",
				right: "20px",
				top: `calc(${theme.spacing(9.5)} + env(safe-area-inset-top, 0))`,
				[theme.breakpoints.up("sm")]: {
					top: `calc(${theme.spacing(10.5)} + env(safe-area-inset-top, 0))`,
					margin: "auto",
					width: `calc(${theme.breakpoints.values.sm}px - 40px)`,
				},
			})}
			icon={false}
			role="presentation"
			action={
				<RegisterAnonModal
					renderButton={(onOpen) => (
						<Button variant="outlined" size="small" onClick={onOpen}>
							{t("register_button")}
						</Button>
					)}
				/>
			}
		>
			<Typography component="h2" variant="h5">
				{t("anon_mode.toast_header")}
			</Typography>
			<Typography sx={{ fontSize: "14px" }}>{t("anon_mode.toast_body")}</Typography>
		</Alert>
	);
}
