import CloseButton from "@components/CloseButton";
import NeedHelpFAQ from "@components/NeedHelpFAQ";
import useLocation from "@hooks/useLocation";
import makeSessionStorageHelper from "@lib/util/makeSessionStorageHelper";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";

const hasBeenClosedStorage = makeSessionStorageHelper<boolean>("/lib/feature/MainView/PreciseLocation");

export default function PreciseLocation() {
	const { t } = useTranslation("common");
	const [locationState] = useLocation();
	const [open, setOpen] = useState(false);

	function onClose() {
		setOpen(false);
		hasBeenClosedStorage.set(true);
	}

	useEffect(() => {
		if (locationState === "denied" && !hasBeenClosedStorage.get()) {
			setOpen(true);
		}
	}, [locationState]);

	return (
		<Dialog open={open} onClose={onClose} PaperProps={{ elevation: 0 }}>
			<CloseButton onClick={onClose} />
			<DialogTitle>{t("location_request.location_disabled_header")}</DialogTitle>
			<DialogContent>
				<Typography fontSize={14} marginBottom="1em">
					{t("location_request.location_disabled_body")}
				</Typography>
				<Typography fontSize={14} marginBottom="1em">
					{t("location_request.location_disabled_body_2")}
				</Typography>
				<Typography fontSize={14}>
					<NeedHelpFAQ />
				</Typography>
			</DialogContent>
		</Dialog>
	);
}
