import DialogButton from "@components/DialogButton";
import GenericBanner from "@components/GenericBanner";
import isIOS from "@lib/util/isIOS";
import { IosShareOutlined, MoreVertOutlined } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Trans, useTranslation } from "next-i18next";
import { ReactNode } from "react";
import { NotificationMessageProps } from "../types";

type IconWithAltProps = {
	children?: ReactNode;
	icon: ReactNode;
};

function IconWithAlt({ children, icon }: IconWithAltProps) {
	return (
		<>
			{icon}
			<span className="sr-only">{children}</span>
		</>
	);
}

export default function InstallPWAMessage({ onClose: handleClose }: NotificationMessageProps) {
	const { t } = useTranslation("common");
	return (
		<GenericBanner
			title={t("install_pwa_banner.banner_title")}
			subtitle={t("install_pwa_banner.banner_subtitle")}
			button={
				<DialogButton
					size="small"
					sx={{ height: "fit-content" }}
					onClose={handleClose}
					renderDialog={(open, onClose) => {
						return (
							<Dialog open={open} onClose={onClose} PaperProps={{ elevation: 0 }}>
								<DialogTitle>{t("install_pwa_dialog.dialog_title")}</DialogTitle>
								<DialogContent sx={{ p: 0 }}>
									<Box component="ol">
										<Typography component="li">
											<Trans
												t={t}
												i18nKey={
													isIOS()
														? "install_pwa_dialog.install_pwa_step_1_ios"
														: "install_pwa_dialog.install_pwa_step_1_android"
												}
												components={[
													<IconWithAlt
														key="0"
														icon={
															isIOS() ? (
																<IosShareOutlined sx={{ verticalAlign: "middle" }} />
															) : (
																<MoreVertOutlined sx={{ verticalAlign: "middle" }} />
															)
														}
													/>,
												]}
											/>
										</Typography>
										<Typography component="li">
											{isIOS()
												? t("install_pwa_dialog.install_pwa_step_2_ios")
												: t("install_pwa_dialog.install_pwa_step_2_android")}
										</Typography>
										<Typography component="li">
											<Trans
												t={t}
												i18nKey={"install_pwa_dialog.install_pwa_step_3"}
												components={[<strong key="0" />]}
											/>
										</Typography>
									</Box>
								</DialogContent>
								<DialogActions>
									<Button onClick={onClose}>{t("close_button")}</Button>
								</DialogActions>
							</Dialog>
						);
					}}
					dialogRoutingName="install-pwa"
				>
					{t("install_pwa_banner.banner_button_label")}
				</DialogButton>
			}
		/>
	);
}
