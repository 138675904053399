import Link from "@lib/components/Link";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Trans, useTranslation } from "next-i18next";

const CustomLink = styled(Link)(({ theme }) => ({
	color: theme.palette.common.black,
	textDecorationColor: theme.palette.common.black,
	fontSize: "11px",
}));

const perryStreetLink = [
	// eslint-disable-next-line react/jsx-key
	<CustomLink href="https://www.perrystreet.com" />,
];
const externalLinks = [
	// it's not that kind of array
	// eslint-disable-next-line react/jsx-key
	<CustomLink href="https://www.perrystreet.com/privacy" />,
	// eslint-disable-next-line react/jsx-key
	<CustomLink href="https://www.perrystreet.com/tos" />,
];

export default function Footer() {
	const { t } = useTranslation("common");
	return (
		<Box
			component={"footer"}
			sx={(theme) => ({
				fontSize: "11px",
				lineHeight: 1.2,
				display: "flex",
				justifyContent: "flex-end",
				alignItems: "center",
				flexDirection: "column",
				color: theme.palette.common.black,
				"a:hover, a:visited": {
					color: "var(--heading-color)",
					textDecorationColor: "var(--heading-color)",
				},
			})}
		>
			<Typography sx={{ fontSize: "inherit", color: "inherit" }}>
				<Trans
					t={t}
					i18nKey="landing.copyright"
					components={perryStreetLink}
					tOptions={{ year: new Date().getFullYear() }}
				/>
			</Typography>
			<Box sx={{ color: "inherit" }}>
				<Trans t={t} i18nKey="landing.links" components={externalLinks} />
			</Box>
		</Box>
	);
}
